
import { mapMutations } from 'vuex'
import { BIconCheckCircleFill } from 'bootstrap-vue'
export default {
  data () {
    return {
      order: null
    }
  },
  components: {
    BIconCheckCircleFill
  },
  mounted () {
    if (this.order && this.order.payment_status !== 2) {
      this.$router.push(this.localeLocation({ name: 'my-orders', params: { orderId: this.$route.params.id } }))
    }
    this.hideLoading()
    this.sendDataLayer()
  },
  computed: {
    gotoOrder () {
      return this.$router.resolve({ name: 'my-orders', params: { id: this.$route.params.id } }).href
    }
  },
  async asyncData ({ params, $axios, $cookies, redirect }) {
    try {
      const { data } = await $axios.$get(`orders/${params.id}`)

      if (data.payment_status !== 2) {
        redirect('/')
      }

      return {
        order: data
      }
    } catch (e) {
      console.log('error', e)
      redirect('/')
    }
  },
  methods: {
    ...mapMutations(['hideLoading']),
    sendDataLayer () {
      if (localStorage.getItem('purchased-' + this.order.id) === this.order.id) {
        return false
      }
      localStorage.setItem('purchased-' + this.order.id, this.order.id)
      if (this.order) {
        const data = this.order
        const pkgType = (data.products[0].packaging_type_id) ? data.products[0].packaging_type_id : data.packaging_type_id
        const csData = JSON.parse(data.products[0].option_json)
        const smeData = JSON.parse(data.products[0].sme_json)
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ ecommerce: null })
        let dimension2 = ''
        if (data.is_brief_design === 1) {
          dimension2 = 'จ้างออกแบบ'
        } else if (data.design_by_yourself === 1) {
          dimension2 = 'ออกแบบออนไลน์'
        } else {
          dimension2 = 'ออกแบบเอง'
        }
        let dimension4 = ''
        const productData = data.products_all[0].product
        if (productData.h) {
          dimension4 = `${productData.w / 10} x ${productData.l / 10} x ${productData.h / 10}`
        } else {
          dimension4 = `${productData.w / 10} x ${productData.l / 10}`
        }
        const paymentMethod = this.$paymentTypeName(data.payment_type)
        const withVat = data.total * (1 + (7 / 100))
        const vatPrice = withVat - data.total
        let totalWithoutDiscount = data.total_without_discount
        if (data.total_without_discount === null || typeof data.total_without_discount === 'undefined') {
          totalWithoutDiscount = data.total
        }
        const cardPaperActiveName = (smeData && 'cardPaperActiveName' in smeData) ? smeData.cardPaperActiveName : ''
        let gsm = ''
        const zipcode = data.zipcode ? data.zipcode : ''
        if (csData) {
          if ('cs_data' in csData) {
            gsm = (('gsm' in csData.cs_data) ? csData.cs_data.gsm : csData.cs_data[0].paper_gsm + '')
          } else if ('summary' in csData) {
            gsm = (('cs_data' in csData.summary) ? csData.summary.cs_data[0].paper_gsm : '')
          }
        }
        let productCode = ''
        let dimension1 = ''
        let dimension3 = ''
        if (data.products[0].mapping_input_id) {
          productCode = `LOCOPACK_${pkgType}` + data.products[0].mapping_input_id.toString().padStart(9, '0')
          dimension1 = (typeof data.products[0] !== 'undefined' ? 'โรงงาน ' + data.products[0].factory_id : '')
          dimension3 = data.products[0].mapping_inputs_name_th
        } else if (data.products[0].rigid_id) {
          productCode = `LOCOPACK_${pkgType}` + data.products[0].rigid_id.toString().padStart(9, '0')
          dimension1 = (typeof data.products[0].rigid.factory_id !== 'undefined' ? 'โรงงาน ' + data.products[0].rigid.factory_id : '')
          dimension3 = data.products[0].rigid.name
        }
        const res = window.dataLayer.push({
          event: 'purchase',
          paymentMethod,
          discount: totalWithoutDiscount - data.total,
          mold: data.print_plate_price,
          dieCut: data.plate_diecut_price,
          moldCost: data.print_plate_price,
          dieCutCost: data.plate_diecut_price,
          creditCardFee: ((parseInt(data.trans_2c2p_creditcard_id) > 0 || parseInt(data.trans_2c2p_online_id) > 0) ? this.calCostFor2C2P(data.total, data.products[0].amount) : 0),
          designFee: data.sum_design_price,
          shippingCost: data.shipping_price,
          ecommerce: {
            purchase: {
              actionField: {
                id: data.order_code,
                revenue: parseFloat(data.total).toFixed(2),
                tax: parseFloat(vatPrice).toFixed(2),
                shipping: 0, // data.shipping_price,
                coupon: ((smeData && 'promotion_code' in smeData) ? smeData.promotion_code : '')
              },
              products: [{
                id: productCode,
                name: ((smeData && 'mapping_inputs' in smeData) ? smeData.mapping_inputs.name_th : ''),
                price: (typeof data.price_per_pcs !== 'undefined' ? data.price_per_pcs : ''),
                category: this.$pkgTypeThaiName(pkgType),
                quantity: data.amount,
                discount: ((csData && csData.discount) ? csData.discount.toFixed(2) : 0),
                brand: 'Locopack',
                dimension1,
                dimension2,
                dimension3,
                dimension4,
                dimension5: cardPaperActiveName,
                dimension6: gsm,
                dimension7: zipcode,
                dimension8: ((smeData && 'color' in smeData) ? this.colorText[pkgType][smeData.color] : ''),
                dimension9: (data.products[0].plate_text + '').replace('พื้นที่', ''),
                dimension10: '',
                dimension11: '',
                dimension12: data.products[0].delivery_by,
                dimension13: data.products[0].production_lead_time
              }]
            }
          }
        })
        if (res) {
          console.log('Purchase done')
        } else {
          console.log('Purchase failed')
        }
      }
    }
  }
}
